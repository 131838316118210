import React, { useEffect, useState } from 'react';
import StyledH2Heading from '../../../common/styledH2Heading/StyledH2Heading';
import { Link } from 'react-router-dom';

const InstagramSection = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${process.env.REACT_APP_INSTAGRAM_KEY}`);
          const result = await response.json();
          setData(result.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, []);

    return (
        <div className='flex flex-col items-center py-10'>
            <div className='w-[90%]'>
                <div className='text-center'><StyledH2Heading fontColor={"text-dark-blue"}>Instagram</StyledH2Heading></div>
                <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3'>
                    {data?.filter(item => item.media_type !== "VIDEO").slice(0, 6).map((item, index) => (
                        <Link to={item.permalink} target='__blank' key={index} className='w-full h-full p-1 bg-white'>
                            <img
                                src={item.media_url}
                                alt={`Instagram Post#${index + 1}`}
                                className='object-cover w-full h-full mb-4'
                            />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default InstagramSection;
