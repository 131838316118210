import './App.css';
import Router from './router';
import { usePath } from './context/PathContext';
import Header from './components/layout/header/Header';
import Footer from './components/layout/footer/Footer';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import { useEffect, useState } from 'react';
import Loader from './components/loader/Loader';

function App() {
  const { path } = usePath();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(loadingTimeout);

  }, [path]);

  return (
    <>
      {isLoading === true ?
        (
          <Loader />
        ) :
        <div className='flex flex-col items-center'>
          <Header />
          <style jsx>{`
        ::-webkit-scrollbar {
          width: 7.5px !important;
          background-color: #F9BE62;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #E01073;
        }
      `}</style>
          <Router />
          <ScrollToTop />
          <Footer />
        </div>
      }
    </>
  );
}

export default App;
