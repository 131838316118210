export const content = {
    layout: {
        headerLinks: [
            {
                title: "Home",
                link: "/"
            },
            {
                title: "Shop",
                link: "https://aimancreates.printsconnection.com/"
            },
            {
                title: "Portfolio",
                link: "/portfolio"
            },
            {
                title: "About",
                link: "/about-us"
            },
            {
                title: "Contact",
                link: "/contact-us"
            },
        ],
        headerIcons: [
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 448 512"><path opacity="1" fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                ),
                link: "https://www.instagram.com/aimancreates/"
            },
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 448 512"><path opacity="1" fill="currentColor" d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                ),
                link: "https://www.linkedin.com/in/aiman-khokhar-commercial-textile-print-designer-961398165/"
            },
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 384 512"><path opacity="1" fill="currentColor" d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" /></svg>
                ),
                link: "https://www.pinterest.com/aimancreatesofficial/"
            },
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" viewBox="0 0 512 512"><path opacity="1" fill="currentColor" d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" /></svg>
                ),
                link: "mailto:hello@aimancreates.com"
            },

        ],
        footerInfo: [
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path opacity="1" fill="currentColor" d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                ),
                title: "Australia",
                link: "https://maps.app.goo.gl/XNfPfKhBoqy7dLGaA"
            },
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path opacity="1" fill="currentColor" d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                ),
                title: "aimancreates.official@gmail.com",
                link: "mailto:aimancreates.official@gmail.com"
            },
            {
                icon: (
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path opacity="1" fill="currentColor" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                ),
                title: "+61 493 294 415",
                link: "https://wa.me/+61493294415"
            },
        ]
    },
    aboutUs: {
        AboutWork: {
            Services: [
                {
                    title: "Seamless Surface Pattern Designs"
                },
                {
                    title: "Placement Print Designs"
                },
                {
                    title: "Tie Dye Designs"
                },
                {
                    title: "Other Texture Prints"
                },
                {
                    title: "Animal Print Designs"
                },
                {
                    title: "Rug Designs"
                },
                {
                    title: "Scarf Designs"
                },
                {
                    title: "Custom Print Design"
                },
            ]
        }
    },
    homepage: {
        Landing: [
            {
                iniTitle: "The most versatile commercial collection focusing on seasonal trends for",
                spanText: "Fashion & Lifestyle"
            },
        ],
        Services: [
            {
                title: "Print Shop",
                imgUrl: "/assets/service1.webp"
            },
            {
                title: "Custom Prints",
                imgUrl: "/assets/service2.webp"
            },
            {
                title: "Design Support",
                imgUrl: "/assets/service3.webp"
            },
        ],
        Clients: [
            {
                logoUrl: "/assets/client1.webp"
            },
            {
                logoUrl: "/assets/client2.webp"
            },
            {
                logoUrl: "/assets/client3.webp"
            },
            {
                logoUrl: "/assets/client4.webp"
            },
            {
                logoUrl: "/assets/client5.webp"
            },
            {
                logoUrl: "/assets/client6.webp"
            },
            {
                logoUrl: "/assets/client7.webp"
            },
            {
                logoUrl: "/assets/client8.webp"
            },
            {
                logoUrl: "/assets/client9.webp"
            },
            {
                logoUrl: "/assets/client10.webp"
            },
            {
                logoUrl: "/assets/client11.webp"
            },
            {
                logoUrl: "/assets/client12.webp"
            },
        ],
        Testimonials: [
            {
                imgUrl: "/assets/clientImg1.webp",
                clientName: "Aniket Pednekar",
                designation: "Aesthetic Nation",
                Review: "We've been working with Aiman for more than a year & her job is beyond expectations. top of the notch designer & one of the best artists we've come across. We highly vouch and recommend Aiman. Wishing you all the luck.",

            },
            {
                imgUrl: "/assets/clientImg3.webp",
                clientName: "Furqan Ahmad",
                designation: "",
                Review: "Aiman is a gifted designer with a unique vision. She brings creativity, skill, and professionalism to the table. I would highly recommend her for any project that requires a delicate balance of creativity and precision. We are deeply grateful for the heart and soul she poured into our patterns and are excited to see them come to life through our products",

            },
            {
                imgUrl: "/assets/clientImg4.webp",
                clientName: "Kasra Rahimi",
                designation: "",
                Review: "Aiman is very talented and went above and beyond to make sure our project matched our expectations. She was patient, professional and did a good job of implementing our feedback along each step of the way. We would not hesitate to work with her on any future projects",

            },
            {
                imgUrl: "/assets/clientImg2.webp",
                clientName: "Amy Richardson",
                designation: "Honey Stone and CO",
                Review: "Aiman was very good with communication right from the start and shared design process throughout the project. Job was completed before due date and any changes requested were completed straight away. I really enjoyed working with Aiman and we will defiantly be hiring her again in the very near future. She is a very talented artist",

            },
        ],
    },
    portfolio: {
        Fashion: [
            {
                imageUrl: "/assets/Portfolio/fashion/1.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/2.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/3.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/4.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/5.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/6.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/7.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/8.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/9.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/10.webp"
            },
            {
                imageUrl: "/assets/Portfolio/fashion/11.webp"
            },
        ],
        Home: [
            {
                imageUrl: "/assets/Portfolio/home/1.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/2.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/3.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/4.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/5.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/6.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/7.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/8.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/9.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/10.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/11.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/12.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/13.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/14.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/15.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/16.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/17.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/18.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/19.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/20.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/21.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/22.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/23.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/24.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/25.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/26.webp"
            },
            {
                imageUrl: "/assets/Portfolio/home/27.webp"
            },
        ]
    }
}

export const contactConfig = {
    YOUR_EMAIL: "aimancreates.official@gmail.com",
    YOUR_FONE: "+61 493 294 415",
    YOUR_SERVICE_ID: "service_77tcuaf",
    YOUR_TEMPLATE_ID: "template_2rk6xpo",
    YOUR_USER_ID: "2pc0rC-Vgr1fBMkmE",
};